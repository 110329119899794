import "core-js/modules/es.array.includes.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.replace-all.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from "vuex";
import { geneticAnalystIllustration, plusCircle, pencilIcon, trashIcon, alertTriangleIcon } from "@debionetwork/ui-icons";
import { errorHandler } from "@/common/lib/error-handler";
import { queryGeneticAnalystByAccountId } from "@debionetwork/polkadot-provider";
import { queryGeneticAnalystServicesByHashId } from "@debionetwork/polkadot-provider";
import { deleteGeneticAnalystService, deleteGeneticAnalystServiceFee } from "@debionetwork/polkadot-provider";
import { GAGetOrders } from "@/common/lib/api";
import { formatUSDTE } from "@/common/lib/balance-format.js";
import WarningDialog from "@/common/components/Dialog/WarningDialog";
export default {
  name: "GAServices",
  components: {
    WarningDialog
  },
  data: () => ({
    geneticAnalystIllustration,
    plusCircle,
    pencilIcon,
    trashIcon,
    alertTriangleIcon,
    formatUSDTE,
    error: "",
    txWeight: "",
    serviceId: "",
    showModal: false,
    showActiveOrder: false,
    cardBlock: false,
    isLoading: false,
    serviceList: [],
    items: [],
    orders: [],
    headers: [{
      text: "Service Name",
      value: "serviceName",
      sortable: true
    }, {
      text: "Description",
      value: "description",
      sortable: true
    }, {
      text: "Total Price",
      value: "price",
      sortable: true
    }, {
      text: "Service Duration",
      value: "duration",
      sortable: true
    }, {
      text: "Action",
      value: "action",
      align: "center",
      sortable: false
    }]
  }),
  computed: {
    ...mapState({
      lastEventData: state => state.substrate.lastEventData,
      api: state => state.substrate.api,
      wallet: state => state.substrate.wallet,
      web3: state => state.web3Store.web3
    })
  },
  watch: {
    lastEventData(e) {
      if (e !== null) {
        const dataEvent = JSON.parse(e.data.toString());
        if (dataEvent[1] === this.wallet.address) {
          const toRefetch = ["GeneticAnalystServiceDeleted", "GeneticAnalystServiceUpdated", "GeneticAnalystServiceCreated"];
          if (toRefetch.includes(e.method)) {
            this.getServiceList();
            this.showModal = false;
            this.isLoading = false;
            this.serviceId = "";
          }
        }
      }
    }
  },
  async mounted() {
    await this.getGAOrders();
    await this.getServiceList();
  },
  methods: {
    async getDeleteServiceFee() {
      this.txWeight = "Calculating...";
      const txWeight = await deleteGeneticAnalystServiceFee(this.api, this.wallet, this.serviceId);
      this.txWeight = "".concat(Number(this.web3.utils.fromWei(String(txWeight.partialFee), "ether")).toFixed(4), " DBIO");
    },
    async getServiceList() {
      this.items = [];
      const analystDetail = await queryGeneticAnalystByAccountId(this.api, this.wallet.address);
      this.serviceList = analystDetail.services;
      for (const serviceId of this.serviceList) {
        try {
          const serviceDetail = await queryGeneticAnalystServicesByHashId(this.api, serviceId);
          const {
            id,
            info: {
              description,
              name: serviceName,
              testResultSample
            }
          } = serviceDetail;
          const currency = serviceDetail.info.pricesByCurrency[0].currency;
          const price = this.formatPrice(serviceDetail.info.pricesByCurrency[0].totalPrice, currency);
          const duration = "".concat(serviceDetail.info.expectedDuration.duration, " ").concat(serviceDetail.info.expectedDuration.durationType);
          const regex = /^(https:\/\/ipfs.debio.network\/ipfs\/)/;
          let newDescription = "";
          if (regex.test(description)) {
            const response = await fetch(description);
            newDescription = await response.text();
          } else {
            newDescription = description;
          }
          const service = {
            id,
            description: newDescription,
            serviceName,
            testResultSample,
            price,
            duration,
            currency
          };
          this.items.push(service);
        } catch (err) {
          console.error(err);
        }
      }
    },
    async getGAOrders() {
      const orders = await GAGetOrders();
      this.orders = orders;
    },
    formatPrice(price, currency) {
      let unit;
      let formatedBalance = 0;
      currency === "USDT" || currency === "USDT.e" || currency === "USDTE" ? unit = "mwei" : unit = "ether";
      formatedBalance = this.web3.utils.fromWei(String(price.replaceAll(",", "")), unit);
      return formatedBalance;
    },
    async showDialog(id) {
      this.serviceId = id;
      this.showModal = true;
      await this.getDeleteServiceFee();
    },
    onDelete() {
      var _this$orders, _this$orders$data;
      const isActiveOrder = (_this$orders = this.orders) === null || _this$orders === void 0 ? void 0 : (_this$orders$data = _this$orders.data) === null || _this$orders$data === void 0 ? void 0 : _this$orders$data.some(order => order._source.service_id === this.serviceId && order._source.status === "Paid");
      if (isActiveOrder) this.showActiveOrder = true;else this.deleteService();
    },
    async deleteService() {
      try {
        this.isLoading = true;
        await deleteGeneticAnalystService(this.api, this.wallet, this.serviceId);
      } catch (e) {
        this.error = await errorHandler(e.message);
        this.showModal = false;
        this.isLoading = false;
      }
    },
    goToDashboard() {
      this.$router.push({
        name: "dashboard"
      });
    }
  }
};
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ga-services"},[_c('div',{staticClass:"ga-services__wrapper"},[_c('ui-debio-error-dialog',{attrs:{"show":!!_vm.error,"title":_vm.error ? _vm.error.title : '',"message":_vm.error ? _vm.error.message : ''},on:{"close":function($event){_vm.error = null}}}),_c('WarningDialog',{attrs:{"show":_vm.showActiveOrder,"title":"Unfinished Order","btnMessage":"Go to Dashboard","message":"You still have active orders to complete. Resume any pending orders before continuing with this process."},on:{"close":function($event){_vm.showActiveOrder = false},"submit":_vm.goToDashboard}}),_c('ui-debio-modal',{staticClass:"ga-services__modal-card",attrs:{"show":_vm.showModal,"show-title":false,"show-cta":false},on:{"onClose":function($event){_vm.showModal = false}}},[_c('div',{staticClass:"ga-services__modal-title"},[_vm._v("Delete Service")]),_c('ui-debio-icon',{attrs:{"icon":_vm.alertTriangleIcon,"stroke":"","size":"88"}}),_c('div',{staticClass:"ga-services__modal-desc"},[_vm._v("By delete this Service, you might not be able to retrieve it back")]),_c('div',{staticClass:"ga-services__tx-weight"},[_c('div',{staticClass:"ga-services__tx-weight-text"},[_vm._v("Estimated transaction weight "),_c('v-tooltip',{staticClass:"visible",attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"dialog-confirmation__trans-weight-icon",staticStyle:{"font-size":"12px"},attrs:{"color":"primary","dark":""}},'v-icon',attrs,false),on),[_vm._v("mdi-alert-circle-outline")])]}}])},[_c('span',{staticStyle:{"font-size":"10px"}},[_vm._v("Total fee paid in DBIO to execute this transaction.")])])],1),_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v(_vm._s(_vm.txWeight))])]),_c('div',{staticClass:"ga-services__modal-button"},[_c('ui-debio-button',{attrs:{"block":"","loading":_vm.isLoading,"color":"secondary"},on:{"click":_vm.onDelete}},[_vm._v("Delete")])],1)],1),_c('ui-debio-banner',{staticClass:"ga-services__banner",attrs:{"title":"Genetic Analyst","subtitle":"Provide service for customer who have been tested previously can get interpretation that leads to improved maintenance.","gradient-color":"tertiary","with-decoration":""}},[_c('template',{slot:"illustration"},[_c('ui-debio-icon',{attrs:{"icon":_vm.geneticAnalystIllustration,"size":_vm.cardBlock ? 250 : 120,"view-box":"0 0 120 120","fill":""}})],1),_c('template',{slot:"cta"},[_c('ui-debio-card',{attrs:{"to":{ name: 'ga-add-services'},"title":"Add Services","sub-title":"Add Genetic Services","tiny-card":"","with-icon":"","width":"250","block":_vm.cardBlock}},[_c('ui-debio-icon',{attrs:{"slot":"icon","icon":_vm.plusCircle,"size":"34","color":"#C400A5","fill":""},slot:"icon"})],1)],1)],2),_c('div',{staticClass:"ga-services__table"},[_c('ui-debio-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.isLoading},scopedSlots:_vm._u([{key:"item.serviceName",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column ga-services__service-name"},[_c('span',[_vm._v(_vm._s(item.serviceName))])])]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column ga-services__description"},[_c('span',[_vm._v(_vm._s(item.description))])])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column ga-services__price"},[_c('span',[_vm._v(_vm._s(item.price)+" "+_vm._s(_vm.formatUSDTE(item.currency)))])])]}},{key:"item.duration",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column ga-services__duration"},[_c('span',[_vm._v(_vm._s(item.duration))])])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"ga-services__action"},[_c('router-link',{attrs:{"to":{ name: 'ga-edit-service', params: { id: item.id, item: item } }}},[_c('ui-debio-icon',{attrs:{"icon":_vm.pencilIcon,"size":"16","role":"button","stroke":""}})],1),_c('ui-debio-icon',{attrs:{"icon":_vm.trashIcon,"size":"16","role":"button","stroke":""},on:{"click":function($event){return _vm.showDialog(item.id)}}})],1)]}}],null,true)},[_c('template',{slot:"prepend"},[_c('div',{staticClass:"ga-services__text"},[_c('h2',{staticClass:"ga-services__table-title"},[_vm._v("My Services")]),_c('p',{staticClass:"ga-services__table-subtitle mb-0"},[_vm._v("List of all Services")])])])],2)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }